<template>
    <div class="page-asap-workflow-details">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Workflows</router-link>

                    <h1 class="heading">Workflow details</h1>

                    <app-tabs v-model="tab" :tabs="tabs" />

                    <router-view :response="response"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'

import { mapGetters } from 'vuex'

import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    data() {
        return {
            referrer: null,
            response: null,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        tabs() {
            const tabs = [
                {
                    id: 'asap-workflow-summary',
                    name: 'asap-workflow-summary',
                    title: 'Summary',
                },
                {
                    id: 'asap-workflow-history',
                    name: 'asap-workflow-history',
                    title: 'History',
                },
            ]

            return tabs
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name)

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            }
        },

        back_to() {
            return this.referrer && this.referrer.name == 'asap-workflows'
                ? this.referrer
                : { name: 'asap-workflows' }
        },
    },

    beforeRouteEnter(to, from, next) {
        const payload = {
            namespace_name: to.params.namespace_name,
            workflow_id: to.params.workflow_id,
            run_id: to.params.run_id,
            SPID: store.getters.current_spid,

        }

        store.dispatch('getWorkflowExecutionHistory', payload)
            .then(response => {
                next(vm => {
                    vm.referrer = from
                    vm.response = response
                })
            })
            .catch(error => {
                store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                next({ name: 'asap-workflows' })
            })
    },
}
</script>

<style lang="scss">
.page-asap-workflow-details {
    padding-bottom: 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .app-tabs {
        margin-bottom: 34px;
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .page-asap-workflow-details {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>